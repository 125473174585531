.map-container {
  position: relative;
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;
  margin-bottom: 4rem;

  h1 {
    font-size: 2em;
    margin-bottom: 4rem;
  }
}

.react-transform-wrapper {
  max-width: 100%;
}


.map-wrap {
  display: flex;
  .map-map {
    width: 50%;
    svg {
      width: 100%;
    }

    .react-transform-wrapper,
    .react-transform-component {
      width: 100%;
    }
  }
  .map-menu {
    padding: 0 2rem;
    text-align: left;
    .title-btn {
      &.active {
        background: black;
        color: white;
      }
    }
    h2 {
      text-align: left;
    }
    ul {
      padding: 0;
      list-style: none;
      text-align: left;
      li {
        margin-bottom: .5rem;
      }
    }
  }
}

.map-contents {
  position: relative;
  width: 100%;
}

.marker {
  // width: 25px;
  // height: 25px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: .5rem;
  // background: white;
  // border: 2px solid black;
  // border-radius: 50%;
  // position: absolute;
  // z-index: 50;

  &.active {
    // background: purple;
    // border: 2px solid black;
    fill: red;
    // color: white;
  }
}

.r1 {
  top: 50%;
  right: 15%;
}
.r2 {
  top: 48.5%;
  right: 15%;
}
.r3 {
  top: 47%;
  right: 15%;
}